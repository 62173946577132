<template>
  <Transition
    :name="isOff ? '' : transition.toString()"
    @before-enter="isExpandTransition && beforeEnter"
    @enter="isExpandTransition && enter"
    @leave="isExpandTransition && leave"
  >
    <slot></slot>
  </Transition>
</template>
<script setup lang="ts">
const props = defineProps({
  transition: {
    type: [String, Boolean],
    default: 'expand',
  },
  group: {
    type: Boolean,
    default: false,
  },
});

const isOff = props.transition === false;
const isExpandTransition = props.transition === 'expand';
const beforeEnter = (el: HTMLElement) => {
  el.style.setProperty('height', 'auto');
};
const enter = (el: HTMLElement) => {
  el.style.setProperty('height', 'auto');
  const { height } = getComputedStyle(el);
  el.style.setProperty('height', '0');
  requestAnimationFrame(() => {
    el.style.setProperty('height', height);
  });
};
const leave = (el: HTMLElement) => {
  const { height } = getComputedStyle(el);
  el.style.setProperty('height', height);
  requestAnimationFrame(() => {
    el.style.setProperty('height', '0');
  });
};

// render(createElement, { data, children, props }) {
//   const isOff = props.transition === false;
//   return createElement(
//     'transition',
//     {
//       ...data,
//       attrs: { name: isOff ? '' : props.transition },
//       on: props.transition === 'expand' && listeners,
//     },
//     children
//   );
// },
</script>
