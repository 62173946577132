<template>
  <div>
    <slot
      name="header"
      v-bind="{
        header,
        isOpen,
        accordionClick,
      }"
    >
    </slot>
    <Expand>
      <div v-show="isOpen">
        <slot />
      </div>
    </Expand>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  openImmediate: {
    type: Boolean,
    default: false,
  },
  setFirstTabOpen: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(props.setFirstTabOpen);

onMounted(() => {
  if (props.openImmediate) {
    isOpen.value = true;
  }
});

const accordionClick = () => {
  isOpen.value = !isOpen.value;
};
</script>
